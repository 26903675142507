import React, { useState } from 'react'
import logo from "../../img/logo/logo.png"
import { useTranslation } from 'react-i18next';
import './header.css'




function Header() {
  const { t } = useTranslation("translate");
  const [isactive, setIsactive] = useState(false);

  const handleActive = () => {
    setIsactive(!isactive);
  }

  
  return (
    <div>
      <header>
          <a href="/">
          <div className="logo">
              <img src={logo} alt="Logo"/>
              <p>ETHIOMARBLE</p>
          </div>
          </a>

          <nav className={`${isactive ? "active" : ""}`}>
            <ul className="nav-links">
              <a href="/" onClick={handleActive}>{t('header.home')}</a>
              <a href="#customers" onClick={handleActive}>{t('header.agent')}</a>
              <a href="#Events" onClick={handleActive}>{t('header.events')}</a>
              <a href='#about' onClick={handleActive}>{t('header.about us')}</a>
              <a href='#product' onClick={handleActive}>{t('header.products')}</a>
              <a href='#contact' onClick={handleActive}>{t('header.contact')}</a>
            </ul>

            <div className='language'>
              {/* <LanguageSwitcher/> */}
            </div>
            <span className="active-nav"></span>
        </nav>
        <div id='menu-icon' onClick={handleActive}>
          <i className={`bx ${isactive ? "bx-x" : "bx-menu"}`} ></i>
        </div>
      </header>
    </div>
  )
}

export default Header;
