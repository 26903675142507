import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/Home";
import { MyProvider } from "./contexts/Context_1";
import Products from "./components/products/Products";
import { PopContext } from "./contexts/PopContext";

function App() {
  return (
    <MyProvider>
      <PopContext>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Products" element={<Products />} />
          </Routes>
        </Router>
      </PopContext>
    </MyProvider>
  );
}

export default App;