import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Mycontext } from '../contexts/Context_1';
import emailjs from '@emailjs/browser';
import { PopUp } from '../contexts/PopContext';


function ContactForm() {
  const { t } = useTranslation("translate");
  const { handleContactStat } = useContext(Mycontext);
  const { handlepopUp } = useContext(PopUp);
  const form = useRef();

  const handleSendEmail = async (e) => {
    e.preventDefault()
    try {
      console.log(e);
      const res = await emailjs.sendForm('service_2n17w2j', 'template_gcxbljd', 
      form.current, 'Vj8jFw-iclQZOE4rY'
      )
        if (res.status !== 200) {
          throw new Error("200 status false");
        } else {
          handlepopUp("")
          e.target[0].value = "";
          e.target[1].value = "";
          e.target[2].value = "";
          e.target[3].value = "";
          e.target[4].value = "";
        }
        
      } catch (error) {
        console.log(error.text);
        handlepopUp("red")
      }
  };


  return (
   <>
    <form ref={form} onSubmit={handleSendEmail}>
      <div className="icon-cont" onClick={handleContactStat}>
        <box-icon name="x"></box-icon>
      </div>
      <div className="input-1">
        <input 
          type="text" 
          name='fullName'
          required 
          placeholder={t("contact.name")} 
        />
        <input 
          type="text"
          name='email' 
          required 
          placeholder={t("contact.email")} 
        />
      </div>
      <div className="input-2">
        <input 
          type="text" 
          name='phone'
          required 
          placeholder={t("contact.tele")} 
        />
        <input type="text" 
          name='subject'
          required 
          placeholder={t("contact.subj")} 
        />
      </div>
      <textarea 
        cols="30"
        rows="10" 
        name='message'
        required 
        placeholder={t("contact.message")} 
      ></textarea>
       <div className="btn-cont">
          <button className="btn" type='submit'>{t("contact.a")}</button>
       </div>
     </form>
    </>
  )
}

export default ContactForm;