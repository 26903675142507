import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './popup.css'
import { PopUp } from '../../contexts/PopContext';

function Popup() {
  const {ispopup, isred} = useContext(PopUp);
  return (
    <div className={`pop_cont ${ispopup && "true"} ${isred}`}>
      {isred ?(<p>Error !</p>): (<p>Done !</p>)}
      <div>
        <i className="bx bx-check-circle"></i>
      </div>
    </div>
  )
}

export default Popup;