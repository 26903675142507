import React from 'react'
import logo from "../../img/logo/logo.png"
import { useTranslation } from 'react-i18next';
import 'boxicons/css/boxicons.min.css';
import './footer.css'


function Footer() {
  const { t } = useTranslation("translate");
  return (
    <>
     {/* ------ Footer section ------- */}
      <section id='footer'>
        <div className="footer-cont">
          <div className="footer-links">

            <div className="footer-1 link-cont">
                <div className="logo">
                  <img src={logo} alt="Logo"/>
                  <p>ETHIOMARBLE</p>
               </div>
              <p>{t("footer.p-1")}</p>
            </div>

            <div className="footer-2 link-cont">
              <p>{t("footer.p-2")}</p>
              <a href="/">{t("header.home")}</a>
              <a href="#about">{t("header.aboutUs")}</a>
              <a href="#product">{t("header.products")}</a>
              <a href="#product">{t("header.marble")}</a>
              <a href="#product">{t("header.limestone")}</a>
            </div>

            <div className="footer-3 link-cont">
              <p>{t("header.products")}</p>
              <a href="products">{t("footer.a-1")}</a>
              <a href="products">{t("footer.a-2")}</a>
              <a href="products">{t("footer.a-3")}</a>
             {/*  <a href="products">{t("footer.a-6")}</a>
              <a href="products">{t("footer.a-8")}</a>
              <a href="products">{t("footer.a-9")}</a> */}

              <div className="social">
                <a href='/' className="fb"><i className='bx bxl-facebook'></i></a>
                <a href='/' className="ins"><i className='bx bxl-instagram' ></i></a>
                <a href='/' className="yt"><i className='bx bxl-youtube' ></i></a>
                <a href='/' className="wa"><i className='bx bxl-whatsapp'></i></a>
              </div>
            </div>

            <div className="footer-4 link-cont">
              <p></p>
              <a href="products">{t("footer.a-4")}</a>
              <a href="products">{t("footer.a-5")}</a>
              <a href="products">{t("footer.a-6")}</a>


              <div className="social">
                <a href='/' className="fb"><i className='bx bxl-facebook'></i></a>
                <a href='/' className="ins"><i className='bx bxl-instagram' ></i></a>
                <a href='/' className="yt"><i className='bx bxl-youtube' ></i></a>
                <a href='/' className="wa"><i className='bx bxl-whatsapp'></i></a>
              </div>
              
            </div>

          </div>

          <div className="footer-copy">
            <p>{t("footer.copy")} <a href='https://www.ibrahimali.net' rel='noreferrer' target='_blank' style={{color: "var(--btn-1)"}}>{t('footer.ibro')}</a></p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer;