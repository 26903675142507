import React from 'react'
import { useTranslation } from 'react-i18next';
import contactimg from "../../img/vectors/contactimg.png"
import ContactForm from '../ContactForm';
import './contact.css'


function Contact() {
  const { t } = useTranslation("translate");

  return (
    <section id='contact'>
    <div className="contact-cont">
      <div className="title">
          <h1>{t("contact.title.h1")}<span>.</span></h1>
      </div>
      <div className="contents">
          <div className="content-left">
            <ContactForm/>
            <div className="content-right">
              <img src={contactimg} alt="contactimg" />
            </div>
          </div>

        </div>
      </div>
  </section>
  )
}

export default Contact