import React from 'react'
import { useTranslation } from 'react-i18next';
import prod_1 from "../../img/marbles/grey_marble.jpg"
import prod_2 from "../../img/marbles/white.jpg"
import prod_3 from "../../img/marbles/blue.jpg"
import './product.css'


function Product() {
  const { t } = useTranslation("translate");

  return (
    <section id='product'>
        <div className="container">
          <div className="title">
            <h1>{t("product.title")}<span>.</span></h1>
          </div>

          <div className="cards-cont">
              <div className="prod-card">
                <div className="img_cont">
                  <img src={prod_1} alt="imac_1" />
                </div>
                <h3>Grey Gojam Marble</h3>
              </div>

              <div className="prod-card">
                <div className="img_cont">
                  <img src={prod_2} alt="imac_1" />
                </div>
                <h3>White Limestone</h3>
              </div>

              <div className="prod-card">
                <div className="img_cont">
                  <img src={prod_3} alt="imac_1" />
                </div>
                <h3>Blue Limestone</h3>
              </div>
          </div>

          <div className="rect">
            <div className="txts">
              <h2>{t("product.txt.h2")}</h2>
              <h3>{t("product.txt.h3")} !</h3>
            </div>
            <div className="btn-cont">
              <a href='/products' className="btn">{t("product.txt.a")}</a>
            </div>
          </div>

        </div>
      </section>

  )
}

export default Product