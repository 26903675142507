import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';

import prod_1 from "../../img/marbles/blue.jpg"
import prod_2 from "../../img/marbles/green.jpg"
import prod_3 from "../../img/marbles/white.jpg"
import prod_4 from "../../img/marbles/grey_marble.jpg"
import prod_5 from "../../img/marbles/beige_marble.jpg"
import prod_6 from "../../img/marbles/white_marble.jpg"
import { useTranslation } from 'react-i18next';
import Promo from '../promo/Promo';
import Popup from '../popup/Popup';
import './products.css'
import '../events/events.css'


function Products() {
  const { t } = useTranslation("translate");
  const prodImg = [ prod_1, prod_2, prod_3, prod_4, prod_5,prod_6]
  
  return (
    <div>
      <Header/>
      <Popup/>
        <section id='Products'>
          <Promo/>
          <div className="Products-container">
              <div className="title"><h1>{t("header.products")} <span>.</span></h1></div>
              <div className="event_cont">
              {prodImg && prodImg.map((item, i) => (
                  <div className="item"><img src={item} alt={`img_${i+1}`} key={`key_${i}`}/>
                    <div className="subtexts">
                        <h2>
                          <span className='subcolor'>{
                            t(`Product.prod-${i+1}.title`)}
                          </span>
                        </h2>
                    </div>
                  </div>

              ))}
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Products;