import React, { createContext, useState } from 'react';

const Mycontext = createContext();

const MyProvider = ({ children }) => {
  const [promoStat, setPromoStat] = useState(false);
  const [contactStat, setContactStat] = useState(false);
  const [ytLink, setYtLink] = useState("");
    

  const handlePromo = (src) => {
    setPromoStat(!promoStat)
    setYtLink(src);
  }
  const handleContactStat = () => {
    setContactStat(!contactStat);
  }


  return (
    <Mycontext.Provider value={{ 
      promoStat, handlePromo, 
      contactStat, handleContactStat,
      ytLink
      }}>
      {children}
    </Mycontext.Provider>
  );
};
export { MyProvider, Mycontext };