import React from 'react';
import './arrow.css'

function Arrow() {
  return (
      <div className="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
  )
}

export default Arrow