// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import global_en from './locales/en/translate.json'
import global_tr from './locales/tr/translate.json'
import global_fr from './locales/fr/translate.json'
import global_dt from './locales/dt/translate.json'
import global_arab from './locales/arab/translate.json'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    resources: {
      en: { translate: global_en },
      tr: { translate: global_tr },
      fr: { translate: global_fr },
      dt: { translate: global_dt },
      arab: { translate: global_arab },
    },
  });

export default i18n;