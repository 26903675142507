import React from 'react'
import './events.css'
import img_1 from "../../img/events/photo_1.jpeg"
import img_2 from "../../img/events/photo_2.jpeg"
import img_3 from "../../img/events/photo_3.jpeg"
import img_4 from "../../img/events/photo_4.jpeg"
import img_5 from "../../img/events/photo_5.jpeg"
import img_6 from "../../img/events/photo_6.jpeg"

function Events() {
  const eventImg = [img_1, img_2, img_3, img_4, img_5, img_6]
  return (
    <section id='Events'>
      <div className="title">
        <h1>Events .</h1>
      </div>
      <div className="event_cont">
        {eventImg && eventImg.map((item, i) => (
          <div className="item"><img src={item} alt={`img_${i+1}`} key={`key_${i}`}/></div>
        ))}
      </div>
    </section>
  )
}

export default Events;