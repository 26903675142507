import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img_1 from "../../img/marbles/white.jpg"
import img_2 from "../../img/marbles/blue.jpg"
import img_3 from "../../img/marbles/green.jpg"
import img_4 from "../../img/marbles/grey_marble.jpg"
import img_5 from "../../img/marbles/beige_marble.jpg"
import img_6 from "../../img/marbles/white_marble.jpg"
import "./Carousel.css"
//import { useTranslation } from 'react-i18next';


const Carousel = () => {

  //const { t } = useTranslation("translate");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:2000, 
  };

  return (
    <div id='Carousel'>
      <Slider {...settings}>
        <div>
          <p>White Limestone</p>
          <img src={img_1} alt="Slide 1" />
        </div>

        <div>
          <p>Blue Limestone</p>
          <img src={img_2} alt="Slide 1" />
        </div>

        <div>
          <p>Green Limestone</p>
          <img src={img_3} alt="Slide 1" />
        </div>

        <div>
          <p>Gojam Marble</p>
          <img src={img_4} alt="Slide 1" />
        </div>

        <div>
          <p>Wollega Marble</p>
          <img src={img_5} alt="Slide 1" />
        </div>

        <div>
          <p>Harar Marble</p>
          <img src={img_6} alt="Slide 1" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
