import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Mycontext } from '../../contexts/Context_1';
import Carousel from '../carousel/Carousel';
import 'boxicons/css/boxicons.min.css';
import './landing.css'


function Landing() {
  const { t } = useTranslation("translate");
  const { handlePromo, promoStat } = useContext(Mycontext);
  const src_0 = "";



  return (
    <section id="home" className={`${promoStat && "active_home"}`}>
        <div className="home-right">
           <div className="slide_cont">
            <Carousel/>
           </div>
        </div>
        
        <div className="home-left">
        <div className='text-animate'><h2>Ethiomarble</h2></div>
          <p>{t('hero.t1')} <span>{t('hero.t2')}</span> {t('hero.p')}</p>    

          <div className="btn-cont">
            <a href='#' className='btn' onClick={()=> handlePromo(src_0)}>Intro</a>
          </div>
          {/* 
          <div className="social">
            <a href='https://www.facebook.com/' className="fb"><i className='bx bxl-facebook'></i></a>
            <a href='https://www.instagram.com/' className="ins"><i className='bx bxl-instagram' ></i></a>
            <a href='https://www.youtube.com/channel/UCk9flKQF502tzxmDo_4VPbw' className="yt"><i className='bx bxl-youtube' ></i></a>
            <a href='https://web.whatsapp.com/send?phone=908508407807&text=Merhaba' className="wa"><i className='bx bxl-whatsapp'></i></a>
          </div> */}
        </div>

      

      </section>
  )
}

export default Landing