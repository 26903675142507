import React from 'react';
import 'boxicons';
import Header from './header/Header';
import About from './about/About';
import Landing from './landing/Landing';
import Footer from './footer/Footer';
import Product from './product/Product';
import Customers from './customers/Customers';
import Contact from './contact/Contact';
import Promo from './promo/Promo';
import Events from './events/Events';
import Popup from './popup/Popup';

function Home() {
  return (
    <div>
      <Popup/>
      <Header/>
      <Promo/>
      <Landing/>
      <Customers/>
      <Events/>
      <About/>
      <Product/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home;