import React from 'react'
import { useState } from 'react';
import { createContext } from 'react'

const PopUp = createContext();

function PopContext({children}) {
  const [ispopup, setIspopup] = useState(false);
  const [isred, setIsred] = useState(null);

  const handlepopUp = (stat) => {
    setIspopup(true)
    setIsred(stat)
    setTimeout(() => {
      setIspopup(false);
    }, 3000);
  }
  return (
    <PopUp.Provider value={{ispopup, handlepopUp, isred}}>
      {children}
    </PopUp.Provider>
  )
}
export { PopContext, PopUp };