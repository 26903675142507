import React from 'react'
import energy from "../../img/icons/energy.png"
import easy from "../../img/icons/easy.png"
import group from "../../img/icons/group.png"
import mutlu from "../../img/agents/mutlu.png"
import dilmer from "../../img/agents/dilmer.png"
import { useTranslation } from 'react-i18next';
//import SliderFunc from '../Slider';
import './customers.css'
import Arrow from '../arrow/Arrow'

function Customers() {
  const { t } = useTranslation("translate");

  return (
    <section id='customers'>
        <div className="customer-container">

          <div className="cards-cont">
            <div className="ccart">
              <div className="icon"><img src={energy} alt="energy" /></div>
              <h2>{t("cart-1.h2")}</h2>
              <p>{t("cart-1.p")}</p>
            </div>
            <div className="ccart">
              <div className="icon"><img src={easy} alt="easy" /></div>
              <h2>{t("cart-2.h2")}</h2>
              <p>{t("cart-2.p").length > 160 ? `${t("cart-2.p").slice(0,160)}...`: t("cart-2.p") }</p>
            </div>
            <div className="ccart">
              <div className="icon"><img src={group} alt="group" /></div>
              <h2>{t("cart-3.h2")}</h2>
              <p>{t("cart-3.p").length > 186 ? `${t("cart-3.p").slice(0,165)}...`: t("cart-3.p") }</p>
            </div>
          </div>

          <div className="title">
            <h1>{t("title.h1")}<span>.</span></h1>
          </div>
          <div className="subtitle">
            <h3>{t("title.h2")}</h3>
            {/* <h2>{t("title.h3")}</h2> */}
          </div>
          {/* <div className="slider-div">
            <SliderFunc/>
          </div> */}
          <div className="agent_cont">
            <div className="agent_1">
              <div className="img_cont">
                <img src={mutlu} alt="mutlu" />
              </div>
              <Arrow/>
              <a href="https://www.mutmak.com/" target='_blank' rel="noreferrer">Mutlu Makina</a>
             </div>
            <div className="agent_2">
              <div className="img_cont">
                <img src={dilmer} alt="dilmer" />
              </div>
              <Arrow/>
              <a href="https://www.dilmermakina.com.tr/" target='_blank' rel="noreferrer">Dilmer Makina</a>
             </div>
          </div>
        </div>
      </section>
  )
}

export default Customers