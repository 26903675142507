import React from 'react'
import { useTranslation } from 'react-i18next';
import aboutimg from "../../img/vectors/aboutimg.png";
import './about.css'


function About() {
  const { t } = useTranslation("translate");
  
  return (
    <section id='about'>
        <div className="container">
          <div className="title">
            <h1>{t("about.title")}<span>.</span></h1>
          </div>

          <div className="contents">
            <div className="content-left">
              {t("about.p").split('<br>').map((p, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <><br/> <br/></>} 
                  {p}
                </React.Fragment>
              ))}
            </div>

            <div className="content-right">
              <img src={aboutimg} alt="aboutimg" />
            </div>
          </div>

        </div>
      </section>
  )
}

export default About